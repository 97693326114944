<template>
  <div class="kyc-status" :class="bgShadow">
    <components :is="kycIcon" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'IconIdentify',
    },
  },

  components: {
    IconIdentifyDark: () => import('@/assets/icons/identify.svg'),
    IconHeadphoneDark: () => import('@/assets/icons/headphone.svg'),
    IconPaperMagnifierDark: () => import('@/assets/icons/paper_magnifier.svg'),
    IconStarCheckDark: () => import('@/assets/icons/star_check.svg'),
    IconIdentifyLight: () => import('@/assets/icons/identify_light.svg'),
    IconHeadphoneLight: () => import('@/assets/icons/headphone_light.svg'),
    IconPaperMagnifierLight: () => import('@/assets/icons/paper_magnifier_light.svg'),
    IconStarCheckLight: () => import('@/assets/icons/star_check_light.svg'),
  },

  computed: {
    kycIcon() {
      return this.currentTheme === 'dark'
        ? `${ this.icon }Dark`
        : `${ this.icon }Light`;
    },

    bgShadow() {
      return this.currentTheme === 'dark'
        ? 'kyc-status--dark'
        : 'kyc-status--light';
    },
  },
};
</script>

<style scoped>
.kyc-status {
  @apply rounded-full h-s48 w-s48;
}

.kyc-status--light {
  background: #74dbb555;
  box-shadow: 0 0 40px #74dbb5;
}

.kyc-status--dark {
  background: #21866555;
  box-shadow: 0 0 40px #218665;
}
</style>
