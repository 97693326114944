<template>
  <div class="status-element">
    <font-awesome-icon
        role="icon"
        :icon="['far', 'check-circle']"
        size="lg"
        class="rounded-full"
        :class="checked ? 'text-text-active-2 bg-background-icon' : 'text-text-inactive'"
    />
    <div class="ml-s12">
      <p class="body-text-x-large">{{ title }}</p>
      <p class="body-text-medium text-border-inverted">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KycStatusElement',

  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    }
  }
};
</script>

<style scoped>
  .status-element {
    @apply flex items-center mb-s32 ml-s12;
  }
</style>
