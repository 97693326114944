import { mapActions } from 'vuex';

const kycStatus = {
  data() {
    return {
      kycStatus: false,
    };
  },

  mounted() {
    this.setModalProps({
      customClose: this.handleClose,
    });
  },

  methods: {
    ...mapActions('ui', [
      'setModalProps',
    ]),
    ...mapActions('user', [
      'loadKycLevel',
      'loadUserPermissions',
    ]),

    async handleClose() {
      this.hideModal();
    },

    verifyKycLevel() {
      if (!this.kycLevel.kyc_level_status) {
        return null;
      }

      const { kyc_level_status, contact_customer_support_reason, next_level_value } = this.kycLevel;

      if (this.accountInReview().verify(kyc_level_status)) {
        this.kycStatus = this.accountInReview().config;
        return this.kycStatus;
      }

      if (this.costumerSupportError().verify(kyc_level_status, contact_customer_support_reason)) {
        this.kycStatus = this.costumerSupportError().config;
        return this.kycStatus;
      }

      if (this.requestProofOfAddress().verify(kyc_level_status)) {
        this.kycStatus = this.requestProofOfAddress().config;
        return this.kycStatus;
      }

      if (this.kycCleared().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.kycCleared().config;
        return this.kycStatus;
      }

      if (this.liteRequired().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.liteRequired().config;
        return this.kycStatus;
      }

      if (this.ssnRequired().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.ssnRequired().config;
        return this.kycStatus;
      }

      if (this.docvRequired().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.docvRequired().config;
        return this.kycStatus;
      }

      if (this.liteFailed().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.liteFailed().config;
        return this.kycStatus;
      }

      if (this.ssnFailed().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.ssnFailed().config;
        return this.kycStatus;
      }

      if (this.ssnReferred().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.ssnReferred().config;
        return this.kycStatus;
      }

      if (this.docvFailed().verify(kyc_level_status, next_level_value)) {
        this.kycStatus = this.docvFailed().config;
        return this.kycStatus;
      }
    },
    costumerSupportError() {
      const config = {
        icon: 'IconHeadphone',
        title: this.$t('kyc.modal.status.contact_dibbs_support'),
        description:this.$t('kyc.modal.status.contact_dibbs_support_description'),
        primaryButton: this.$t('kyc.modal.status.contact_dibbs_support'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'supportPage',
      };

      const verify = (kycLevelStatus, contactSupportReason) =>
        contactSupportReason === 'something went wrong' ||
        kycLevelStatus === 'contact_customer_support' ||
        kycLevelStatus === 'frozen';
      return { config, verify };
    },

    accountInReview() {
      const config = {
        icon: 'IconPaperMagnifier',
        title: this.$t('kyc.modal.status.trading_account_under_review'),
        description: this.$t('kyc.modal.status.trading_account_under_review_description'),
        primaryButton: this.$t('kyc.modal.status.close'),
        action: 'close',
      };
      const verify = (kycLevelStatus) =>
        kycLevelStatus === 'queue' ||
        kycLevelStatus === 'poa_review_pending' ||
        kycLevelStatus === 'pob_review_pending';
      return { config, verify };
    },

    requestProofOfAddress() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.require_proof_of_address'),
        primaryButton: this.$t('kyc.modal.status.upload_document'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'poaPage',
      };
      const verify = (kycLevelStatus) => kycLevelStatus === 'request_poa';
      return { config, verify };
    },

    liteFailed() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.review_identify'),
        description: this.$t('kyc.modal.status.review_identify_description'),
        primaryButton: this.$t('kyc.modal.status.review_identify_information'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'countryPage',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'resubmit' && nextLevelValue.value == 2000 && nextLevelValue.description === 'lite';
      return { config, verify };
    },

    ssnFailed() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.review_identify'),
        description: this.$t('kyc.modal.status.review_identify_description'),
        primaryButton: this.$t('kyc.modal.status.review_identify_information'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'ssnPage',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'resubmit' && nextLevelValue.value == 3000 && nextLevelValue.description === 'ssn';
      return { config, verify };
    },

    ssnReferred() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.complete_trading_account_description'),
        primaryButton: this.$t('kyc.modal.status.review_identify_information'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'ssnPage',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'refer' && nextLevelValue.value === 3000 && nextLevelValue.description === 'ssn';
      return { config, verify };
    },

    docvFailed() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.ensure_image_upload'),
        primaryButton: this.$t('kyc.modal.status.review_identify_information'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'docvPage',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'resubmit' && nextLevelValue.value == 4000 && nextLevelValue.description === 'docv';
      return { config, verify };
    },

    liteRequired() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.provide_identity_information_to_enable'),
        primaryButton: this.$t('kyc.modal.status.complete_trading_account'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'countryPage',
      };

      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'accept' && nextLevelValue.value == 2000 && nextLevelValue.description === 'lite';
      return { config, verify };
    },

    ssnRequired() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.complete_trading_account_description'),
        primaryButton: this.$t('kyc.modal.status.complete_trading_account'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'ssnPage',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        (kycLevelStatus === 'accept' || kycLevelStatus === 'refer') &&
        nextLevelValue.value == 3000 &&
        nextLevelValue.description === 'ssn';
      return { config, verify };
    },

    docvRequired() {
      const config = {
        icon: 'IconIdentify',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.identify_to_enable_withdrawal'),
        primaryButton: this.$t('kyc.modal.status.complete_trading_account'),
        secondaryButton: this.$t('kyc.modal.status.close'),
        action: 'docvPage',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'refer' && nextLevelValue.value == 4000 && nextLevelValue.description === 'docv';
      return { config, verify };
    },

    kycCleared() {
      const config = {
        icon: 'IconStarCheck',
        title: this.$t('kyc.modal.status.complete_trading_account'),
        description: this.$t('kyc.modal.status.features_unlocked'),
        primaryButton: this.$t('kyc.modal.status.close'),
        action: 'close',
      };
      const verify = (kycLevelStatus, nextLevelValue) =>
        kycLevelStatus === 'accept' &&
        (nextLevelValue === null || (nextLevelValue.value == 4000 && nextLevelValue.description === 'docv'));

      return { config, verify };
    },

    goToDocPage() {
      this.showModal('KycDocuments');
    },

    async goToCountryPage() {
      this.showModal('KycCountry');
    },

    goToSSNPage(){
      this.showModal('KycSSN');
    },

    goToSupportPage() {
      this.$router.push({
        name: 'support',
      });
    },

    goToPoaPage() {
      this.showModal('KycProofOfAddress');
    },
  },
  computed: {
    kycLevel() {
      return this.$store.getters['user/getKycLevel'];
    },
    userPermissions() {
      return this.$store.getters['user/getUserPermissions'];
    },
  },
};

export default kycStatus;
