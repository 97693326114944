<template>
  <div class="status">
    <h1 class="headline-small">
      {{ title || $t("kyc.complete_trading_account") }}
    </h1>

    <div data-testid="description" v-if="description" class="status__description">
      <KycIcon :icon="icon" class="mr-s16" />
      <p class="body-text-large">
        {{ description }}
      </p>
    </div>

    <div class="separator" />

    <div class="subheadline-x-medium mb-s20">
      {{ $t('kyc.modal.status.provide_identity_information') }}
    </div>
    <KycStatusElement
      v-for="(option, index) in options.filter(o => o.groupBy === 'provide_identity_info')"
      :key="`status_provide_identity_info_${index}`"
      :title="option.title"
      :description="option.description"
      :checked="option.isChecked"
    />

    <div class="subheadline-x-medium mt-s40 mb-s20">
      {{ $t('kyc.modal.status.verify_identity_information') }}
    </div>
    <KycStatusElement
        v-for="(option, index) in options.filter(o => o.groupBy === 'verify_identity_info')"
        :key="`status_verify_identity_info_${index}`"
        :title="option.title"
        :description="option.description"
        :checked="option.isChecked"
    />

    <ButtonV2
     @onClick="$emit('onSubmit')"
      data-testid="primary-button"
      data-cy="primary-button"
      version="primary"
      class="mt-s48"
      :label="primaryButton || $t('kyc.complete_trading_account')"
      size="medium"
      wide
    />
    <ButtonV2
      v-if="secondaryButton"
      data-testid="secondary-button"
      data-cy="secondary-button"
      @onClick="$emit('onClose')"
      :label="secondaryButtonLabel"
      class="mt-s16"
      version="secondary"
      size="medium"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import KycIcon from '@/modules/user/KycIcon/index.vue';
import KycStatusElement from '@/modules/user/Modals/KycStatus/KycStatusElement';

export default {
  name: 'KycStatusLayout',
  components: {
    ButtonV2,
    KycIcon,
    KycStatusElement,
  },

  props: {
    icon: {
      type: String,
      required: false,
    },
    title: { type: String, required: false },
    description: { type: String, required: false },
    options: {
      type: Array,
    },
    secondaryButton: { type: String, required: false },
    primaryButton: { type: String, required: false },
  },

  computed: {
    secondaryButtonLabel() {
      return typeof this.secondaryButton === 'boolean'
        ? this.$t('kyc.close')
        : this.secondaryButton;
    },

    bgShadow() {
      return this.currentTheme === 'dark' ? 'status__icon--dark':'status__icon--light';
    }
  },
};
</script>

<style scoped>
.status__description {
  @apply flex mt-s24 mb-s24 max-w-2xs items-center;
}

.separator {
  @apply border-b border-border mb-s24;
}
</style>
