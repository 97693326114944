<template>
  <div v-if="isLoading" :style="{ width: '450px', height: '530px' }">
    <Loading version="v3" />
  </div>
  <KycStatusLayout
    v-else-if="kycStatus && kycLevel"
    :icon="kycStatus.icon"
    :title="kycStatus.title"
    :description="kycStatus.description"
    :options="options"
    :secondaryButton="kycStatus.secondaryButton"
    :primaryButton="kycStatus.primaryButton"
    @onSubmit="handlerSubmit(kycStatus.action)"
    @onClose="close"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Loading } from '@/components/misc';
import KycStatusLayout from './layout.vue';
import KycStatusMixin from '@/mixins/kycStatus';

export default {
  name: 'KycStatus',
  mixins: [KycStatusMixin],
  components: {
    Loading,
    KycStatusLayout,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    ...mapActions('rewards', ['getRewardsPendingNotifications']),
    ...mapActions('ui', ['hideToast']),

    async handlerSubmit(action) {
      if (action === 'close') {
        await this.close();
      }

      if (action === 'supportPage') {
        await this.close();
        this.goToSupportPage();
      }

      if (action === 'ssnPage') {
        this.goToSSNPage();
      }

      if (action === 'docvPage') {
        this.goToDocPage();
      }

      if (action === 'countryPage') {
        await this.goToCountryPage();
      }

      if (action === 'poaPage') {
        this.goToPoaPage();
      }
    },

    async close() {
      this.hideModal();
      await this.getRewardsPendingNotifications();
    },

    async loaded() {
      if (!this.kycLevel) {
        await this.loadUserPermissions();
        await this.loadKycLevel();
      }

      this.verifyKycLevel();
      this.isLoading = false;
    },
  },

  computed: {
    ...mapGetters('user', [
      'getKycLevelForAmplitude',
      'isEmailVerified',
    ]),

    options() {
      const { deposit, withdraw, sell_with_dibbs } = this.userPermissions;

      return [
        {
          title: this.$t('kyc.modal.status.enable_deposits'),
          description: this.$t('kyc.modal.status.enable_deposits_description'),
          isChecked: !!deposit,
          groupBy: 'provide_identity_info'
        },
        {
          title: this.$t('kyc.modal.status.enable_trades'),
          description: this.$t('kyc.modal.status.enable_trades_description'),
          isChecked: !!deposit,
          groupBy: 'provide_identity_info'
        },
        {
          title: this.$t('kyc.modal.status.earn_rewards'),
          description: this.$t('kyc.modal.status.earn_rewards_description'),
          isChecked: !!deposit,
          groupBy: 'provide_identity_info'
        },
        {
          title: this.$t('kyc.modal.status.enable_withdrawals'),
          description: this.$t('kyc.modal.status.enable_withdrawal_description'),
          isChecked: !!withdraw,
          groupBy: 'verify_identity_info'
        },
        {
          title: this.$t('kyc.modal.status.increase_deposit_limits'),
          description: this.$t('kyc.modal.status.increase_deposit_limits_description'),
          isChecked: !!withdraw,
          groupBy: 'verify_identity_info'
        },
        {
          title: this.$t('kyc.modal.status.sell_with_dibbs'),
          description: this.$t('kyc.modal.status.sell_with_dibbs_description'),
          isChecked: !!sell_with_dibbs,
          groupBy: 'verify_identity_info'
        },
      ];
    },
  },
  async mounted() {
    this.hideToast();

    if(!this.isEmailVerified) {
      return this.showModal('EmailNotVerified');
    }

    if (!this.globalIsLoading) {
      await this.loaded();
    }

    await this.$store.dispatch('events/track', {
      event: 'KYC_STATUS_VIEWED',
      variables: {
        ...this.getKycLevelForAmplitude,
      },
    });
  },
  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>
